<template>
  <div>
    <ul>
      <li v-for="(item,idx) in list"
          :key="idx"
          :class="activeItem === item ? 'active-item' : ''"
          @click="switchItem(item)">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Statistical',
  data() {
    return {
      list: ['Day', 'Week', 'Month', 'Year'],
      activeItem: 'Day'
    };
  },
  mounted() {

  },
  methods: {
    switchItem(curItem) {
      this.activeItem = curItem;
    }
  }
};
</script>

<style scoped>
ul{
  list-style:none;
  padding:0;
}
ul li{
  float:left;
  width:5%;
  border:1px solid #999;
  line-height:32px;
  text-align: center;
  cursor: pointer;
}
ul li:not(:last-child){
  border-right:0;
}
ul li:last-child{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
ul li:first-child{
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.active-item{
  background-color:#007DFF ;
  color:#FFF;
}
</style>
